.globalFooter {
    background: $gray-22;
    box-sizing: border-box;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    width: 100%;

    @include breakpoint-up(medium) {
        padding: 100px;
    }

    .logo {
        position: relative;
        width: 39px;
        height: 42px;
        text-indent: -999em;
        margin-bottom: 25px;
        z-index: 1;

        @include breakpoint-up(medium) {
            width: 65px;
            height: 70px;
            margin-bottom: 70px;
        }

        a {
            display: block;
            height: 100%;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100%;

            &.light {
                background-image: url(/images/logo-small-light.svg);
            }
        }
    }

    .menus {
        @include breakpoint-up(large) {
            display: flex;
            justify-content: space-between;
        }

        .menu_desktop {
            display: none;

            @include breakpoint-up(large) {
                display: block;
            }
        }
    }

    .menu {
        a {
            color: rgba($white, 1);

            &:hover {
                color: rgba($white, 0.7);
            }
        }

        &.menu_desktop {
            a {
                color: rgba($white, 0.7);

                &:hover {
                    color: rgba($white, 1);
                }
            }
        }
    }

    .footer {
        box-sizing: border-box;
        color: rgba($white, 0.8);
        font-size: 13px;
        line-height: 1.6;
        padding-top: 25px;

        @include breakpoint-up(medium) {
            font-size: 15px;
            padding-top: 100px;
        }
    }
}
