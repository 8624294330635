.page-content {
    box-sizing: border-box;
    max-width: 1180px;
    padding: 25px;

    @include breakpoint-up(medium) {
        padding: 100px;
    }

    & + .page-content {
        padding-top: 0;
    }
}
