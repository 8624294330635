.page-slider {
    .slider {
        margin-left: -20px;
        position: relative;

        @include breakpoint-up(medium) {
            margin-left: -30px;
        }

        .slider-wrapper {
            display: flex;
        }

        .slider-slide {
            flex-shrink: 0;
            width: 70%;
            font-size: 13px;
            line-height: 1.6;
            padding-left: 20px;
            box-sizing: border-box;
            max-width: 500px;

            @include breakpoint-up(medium) {
                padding-left: 30px;
                font-size: 15px;
            }

            .img {
                display: block;
                padding-top: 100%;
                position: relative;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            p {
                margin-top: 5px;

                @include breakpoint-up(medium) {
                    margin-top: 7px;
                }

                &.title {
                    margin-top: 10px;
                    font-size: 15px !important;
                    margin-bottom: 3px;
                    font-weight: $font-weight-bold;

                    @include breakpoint-up(medium) {
                        margin-top: 20px;
                        font-size: 18px !important;
                    }
                }
            }
        }

        .slider-prev,
        .slider-next {
            position: absolute;
            top: 50%;
            margin-top: -12px;
            cursor: pointer;
            width: 73px;
            height: 23px;
            opacity: 0.8;
            background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzNweCIgaGVpZ2h0PSIyM3B4IiB2aWV3Qm94PSIwIDAgNzMgMjMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8cGF0aCBkPSJNNzEsMTEuNSBMMC43OTY4NzUsMTEuNSBMNzEsMTEuNSBaIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+CiAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoIiBzdHJva2U9IiNGRkZGRkYiIHBvaW50cz0iNjIgMSA3MiAxMS41IDYyIDIyIDYyIDIyIj48L3BvbHlsaW5lPgogICAgPC9nPgo8L3N2Zz4=") no-repeat 50%;
            z-index: 2;
            transition: opacity 0.3s ease;
            display: none;

            @include breakpoint-up(large) {
                display: block;
            }

            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
        }

        .slider-next {
            right: -100px;
        }

        .slider-prev {
            left: -70px;
            transform: rotate(180deg);
        }
    }

    &.clients {
        .slider {
            .slider-slide {
                max-width: 400px;

                .img {
                    background-color: rgba(224, 224, 224, 0.3);
                }

                img {
                    height: 60%;
                    left: 20%;
                    object-fit: contain;
                    top: 20%;
                    width: 60%;
                }
            }

            .slider-prev,
            .slider-next {
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzNweCIgaGVpZ2h0PSIyM3B4IiB2aWV3Qm94PSIwIDAgNzMgMjMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+CiAgICAgICAgPHBhdGggZD0iTTcxLDExLjUgTDAuNzk2ODc1LDExLjUgTDcxLDExLjUgWiIgaWQ9IlBhdGgiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPgogICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgc3Ryb2tlPSIjMDAwMDAwIiBwb2ludHM9IjYyIDEgNzIgMTEuNSA2MiAyMiA2MiAyMiI+PC9wb2x5bGluZT4KICAgIDwvZz4KPC9zdmc+") no-repeat 50%;
            }
        }
    }
}
