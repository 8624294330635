@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        local("HelveticaNowDisplay-Light"),
        url("/fonts/HelveticaNowDisplay-Light.woff2") format("woff2"),
        url("/fonts/HelveticaNowDisplay-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        local("HelveticaNowDisplay-Regular"),
        url("/fonts/HelveticaNowDisplay-Regular.woff2") format("woff2"),
        url("/fonts/HelveticaNowDisplay-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNowDisplay";
    src:
        local("HelveticaNowDisplay-Bold"),
        url("/fonts/HelveticaNowDisplay-Bold.woff2") format("woff2"),
        url("/fonts/HelveticaNowDisplay-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
