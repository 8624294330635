.section {
    font: 17px/1.35 $font-family-now;
    color: $gray-22;

    @include breakpoint-up(medium) {
        font-size: 18px;
    }

    @include breakpoint-up(large) {
        display: flex;
        min-height: 100vh;
    }

    .bg {
        box-sizing: border-box;
        display: block;
        height: 100vw;
        position: relative;
        margin: -40px -25px;

        @include breakpoint-up(medium) {
            height: 100vw;
            margin: -80px -60px;
        }

        @include breakpoint-up(large) {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &.light,
        &.dark {
            @include breakpoint-up(large) {
                bottom: 70px;
                height: auto;
                left: 70px;
                right: 70px;
                top: 70px;
                width: auto;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            object-position: center;
        }
    }
}
