/** Import theme mixins */
@import "utilities/mixins";

/** Import theme commond styles */
@import "common/fonts";
@import "common/variables";
@import "common/reset";

/** Import everything from autoload */
;

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";
@import "~swiper/css/bundle";

/** Import theme styles */
@import "common/global";
@import "components/block";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/menu";
@import "components/section";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "sections/contact";
@import "sections/content";
@import "sections/dual";
@import "sections/form";
@import "sections/intro";
@import "sections/slider";
@import "sections/swiper";
@import "sections/textImage";
