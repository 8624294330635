.menu {
    font-size: 17px;
    font-weight: $font-weight-bold;
    line-height: 1.2;

    @include breakpoint-up(medium) {
        font-size: 18px;
    }

    ul {
        list-style: none;
    }

    li {
        margin: 10px 0;

        @include breakpoint-up(medium) {
            margin: 15px 0;
        }
    }

    a {
        color: #979596;
        display: inline-block;
        transition: color 0.2s ease;

        &:hover {
            color: $white;
        }
    }

    .main-menu {
        font-size: 26px;
        line-height: 1.1;
        margin-bottom: 25px;

        @include breakpoint-up(medium) {
            font-size: 45px;
            margin-bottom: 40px;
        }

        li {
            margin: 15px 0;
        }

        a {
            color: $white;
        }
    }
}
