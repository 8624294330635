.page-textImage {
    img {
        height: auto !important;
        max-width: 100%;
    }

    .image {
        img {
            display: block;
            margin: 0 auto;
            max-width: 50%;
            max-height: 100%;

            @include breakpoint-up(large) {
                max-width: 75%;
            }
        }
    }
}
