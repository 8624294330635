html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    overflow-y: scroll;
}

body {
    font: 17px/1.5 $font-family-now;
    color: $gray-22;

    @include breakpoint-up(medium) {
        font-size: 18px;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

strong {
    font-weight: $font-weight-bold;
}

h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: $font-weight-bold;
    margin-top: 30px;
    margin-bottom: 10px;

    @include breakpoint-up(medium) {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &:first-child {
        margin-top: 0;
    }
}

p.caption {
    line-height: 1.4 !important;

    & + div {
        margin-top: 30px;

        @include breakpoint-up(medium) {
            margin-top: 40px;
        }
    }
}

a.arrow-link {
    background-image: url("/images/arrow-left--pink.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 42px;
    color: $pink;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    opacity: 1;
    transition: opacity 0.2s ease;
    padding-right: 54px;

    &:hover {
        opacity: 0.7;
    }
}

.text-big {
    font-size: 26px;
    line-height: 1.2;
    font-weight: $font-weight-bold;

    @include breakpoint-up(medium) {
        font-size: 40px;
    }

    @include breakpoint-up(xxlarge) {
        font-size: 45px;
    }
}

.text-superbig {
    font-size: 26px;
    line-height: 1.2;
    font-weight: $font-weight-bold;
    max-width: 700px;

    @include breakpoint-up(medium) {
        font-size: 45px;
    }

    @include breakpoint-up(xxlarge) {
        font-size: 54px;
    }
}

.main > *:first-child:not(.page-intro):not(.page-contact) {
    padding-top: 80px;

    @include breakpoint-up(medium) {
        padding-top: 0;
    }
}
