.page-intro {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: $white;

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        &::after {
            background-color: rgba(0, 0, 0, 0.3);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        position: relative;
        z-index: 2;
        padding: 80px 25px 40px;
        color: $gray-22;

        @include breakpoint-up(medium) {
            padding: 80px 60px;
        }

        @include breakpoint-up(xlarge) {
            padding: 80px 100px;
        }
    }

    .title {
        font-size: 35px;
        font-weight: $font-weight-bold;
        line-height: 1.2;

        @include breakpoint-up(medium) {
            max-width: 1100px;
            font-size: 54px;
            line-height: 1.18;
        }

        @include breakpoint-up(xxlarge) {
            font-size: 69px;
            line-height: 1.08;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .crumbs {
        display: block;
        margin-top: 20px;
        color: rgba($black, 0.5);
        font-size: 15px;

        @include breakpoint-up(medium) {
            margin-top: 60px;
            font-size: 1em;
        }

        strong {
            font-weight: $font-weight-light;
        }
    }

    .arrow {
        cursor: pointer;
        position: absolute;
        bottom: 25px;
        right: 25px;
        width: 23px;
        height: 50px;
        z-index: 3;
        content: "";
        opacity: 0.3;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjMgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGcgc3Ryb2tlPSIjMjIyMjIyIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgIDxwYXRoIGQ9Ik0xMS41LDQ4IEwxMS41LDAuNjY3OTY4NzUgTDExLjUsNDggWiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPgogICAgPHBvbHlsaW5lIHBvaW50cz0iMjIgMzkgMTEuNSA0OSAxIDM5IDEgMzkiPjwvcG9seWxpbmU+CiAgPC9nPgo8L3N2Zz4K");
        transition: opacity 0.2s ease;

        @include breakpoint-up(large) {
            width: 23px;
            height: 73px;
            right: 50px;
            bottom: 70px;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSI3M3B4IiB2aWV3Qm94PSIwIDAgMjMgNzMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGcgc3Ryb2tlPSIjMjIyMjIyIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgIDxwYXRoIGQ9Ik0xMS41LDcxIEwxMS41LDAuNzk2ODc1IEwxMS41LDcxIFoiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4KICAgIDxwb2x5bGluZSBwb2ludHM9IjIyIDYyIDExLjUgNzIgMSA2MiAxIDYyIj48L3BvbHlsaW5lPgogIDwvZz4KPC9zdmc+Cg==");
        }
    }

    &.dark {
        .content {
            color: $white;
        }

        .crumbs {
            color: rgba($white, 0.5);
        }

        .arrow {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjMgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGcgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgIDxwYXRoIGQ9Ik0xMS41LDQ4IEwxMS41LDAuNjY3OTY4NzUgTDExLjUsNDggWiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPgogICAgPHBvbHlsaW5lIHBvaW50cz0iMjIgMzkgMTEuNSA0OSAxIDM5IDEgMzkiPjwvcG9seWxpbmU+CiAgPC9nPgo8L3N2Zz4K") no-repeat center;

            @include breakpoint-up(large) {
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSI3M3B4IiB2aWV3Qm94PSIwIDAgMjMgNzMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGcgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgIDxwYXRoIGQ9Ik0xMS41LDcxIEwxMS41LDAuNzk2ODc1IEwxMS41LDcxIFoiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4KICAgIDxwb2x5bGluZSBwb2ludHM9IjIyIDYyIDExLjUgNzIgMSA2MiAxIDYyIj48L3BvbHlsaW5lPgogIDwvZz4KPC9zdmc+Cg==");
            }
        }
    }
}
