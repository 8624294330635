.block {
    box-sizing: border-box;
    padding: 40px 25px;
    position: relative;
    overflow: hidden;

    @include breakpoint-up(medium) {
        padding: 80px 60px;
    }

    @include breakpoint-up(large) {
        min-height: none;
        padding: 60px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include breakpoint-up(xlarge) {
        padding: 100px;
    }

    &.left {
        @include breakpoint-up(large) {
            order: 1;
        }
    }

    &.right {
        @include breakpoint-up(large) {
            order: 2;
        }
    }

    &.small {
        @include breakpoint-up(large) {
            width: (100% / 3);
        }
    }

    &.big {
        @include breakpoint-up(large) {
            padding: 120px;
            width: ((100% / 3) * 2);
        }

        @include breakpoint-up(xlarge) {
            padding: 160px;
        }
    }

    &.wide {
        @include breakpoint-up(large) {
            padding: 120px;
            width: 100%;
        }

        @include breakpoint-up(xlarge) {
            padding: 160px;
            width: 100%;
        }
    }

    &.bottom {
        @include breakpoint-up(large) {
            justify-content: flex-end;
        }
    }

    &.light {
        background: rgba(224, 224, 224, 0.3);
    }

    &.dark {
        background: #222;
        color: #fff;
    }

    &.blue {
        background: $blue;
        color: $white;
    }
}
