// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$white:   #fff;
$gray-f8: #f8f8f8;
$gray-f2: #f2f2f2;
$gray-e7: #e7e7e7;
$gray-d6: #d6d6d6;
$gray-aa: #aaa;
$gray-88: #888;
$gray-55: #555;
$gray-33: #333;
$gray-22: #222;
$gray-11: #111;
$black:   #000;

$blue: #94cdea;
$yellow: #ffcc01;
$pink: #e6007e;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-system:          system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-system;
$font-family-now:             "HelveticaNowDisplay", $font-family-system;
// stylelint-enable value-keyword-case

$font-weight-light:           300;
$font-weight-normal:          normal;
$font-weight-bold:            bold;

$font-size-base:              1.0625rem;
$line-height-base:            1.47059 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// $grid-breakpoints: (
//   xs: 0,
//   sm: 320px,
//   md: 734px,
//   lg: 1068px,
//   xl: 1441px
// );

$breakpoints: (
    xsmall:   0,
    small:    420px,
    medium:   768px,
    large:    1024px,
    xlarge:   1280px,
    xxlarge:  1680px,
);

/** Colors */
$brand-primary:         #525ddc;

/** Box Model  */
$spacer:                2rem;
