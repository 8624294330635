/*
 * Header Logo
 */
#logo {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 119px;
    height: 43px;
    z-index: 101;
    text-indent: -999em;

    @include breakpoint-up(medium) {
        width: 159px;
        height: 56px;
        top: 70px;
        left: 100px;
    }

    html.menu-open & {
        position: fixed;
    }

    a {
        display: block;
        height: 100%;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity 0.4s ease;
        background-size: 100%;

        &.light {
            background-image: url(/images/logo-light.svg);
            opacity: 0;

            html.menu-open &,
            body.dark & {
                opacity: 1;
            }
        }

        &.dark {
            background-image: url(/images/logo.svg);

            html.menu-open &,
            body.dark & {
                opacity: 0;
            }
        }
    }
}

/*
 * Header Handle
 */
#header-handle {
    position: fixed;
    top: 25px;
    right: 25px;
    width: 40px;
    height: 40px;
    z-index: 200;
    cursor: pointer;

    @include breakpoint-up(large) {
        top: 0;
        right: auto;
        left: 0;
        width: 50px;
        bottom: 0;
        height: auto;
    }

    &::after,
    &::before {
        content: "";
        position: absolute;
        left: 5px;
        width: 30px;
        height: 1px;
        background: $gray-22;
        transition: transform 0.3s ease, background 0.3s ease, top 0.3s ease;

        @include breakpoint-up(large) {
            left: auto;
            top: 50%;
            margin-top: -25px;
            height: 50px;
            width: 1px;
            transition: background 0.3s ease;
        }
    }

    &::before {
        top: 15px;

        @include breakpoint-up(large) {
            top: 50%;
            left: 20px;
        }

        html.menu-open &,
        body.dark & {
            background: $white;
        }
    }

    &::after {
        top: 25px;

        @include breakpoint-up(large) {
            top: 50%;
            right: 20px;
        }

        html.menu-open &,
        body.dark & {
            background: $white;
        }
    }
}

/*
 * Header Content
 */
#header {
    background: $gray-22;
    box-sizing: border-box;
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 25px;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    width: 100%;
    z-index: 100;

    @include breakpoint-up(medium) {
        padding: 100px;
        padding-right: 50px;
    }

    @include breakpoint-up(large) {
        transform: translateX(-100%);
        width: 850px;
    }

    html.menu-open & {
        transform: translateY(0);

        @include breakpoint-up(large) {
            transform: translateX(0);
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        color: #696768;
        font-size: 13px;
        line-height: 1.6;
        padding: 0 25px 25px;

        @include breakpoint-up(medium) {
            font-size: 15px;
            padding: 0 50px 70px 100px;
        }
    }
}
