.page-form {
    background: #222;
    position: relative;
    min-height: 100vh;

    .text-superbig {
        margin-bottom: 25px;

        @include breakpoint-up(medium) {
            margin-bottom: 40px;
        }
    }

    .block.big {
        @include breakpoint-up(large) {
            padding: 100px !important;
        }
    }

    .links {
        li {
            margin-top: 15px;
            font-weight: 500;

            a {
                color: $white;
                opacity: 0.5;
                transition: opacity 0.2s ease;
                display: inline-block;
                position: relative;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
