.page-contact {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: $white;

    .content {
        position: relative;
        z-index: 2;
        padding: 80px 25px;
        color: $gray-22;

        @include breakpoint-up(medium) {
            padding: 80px 60px;
        }

        @include breakpoint-up(xlarge) {
            padding: 80px 100px;
        }
    }

    .title {
        font-size: 35px;
        font-weight: $font-weight-bold;
        line-height: 1.2;

        @include breakpoint-up(medium) {
            max-width: 1100px;
            font-size: 69px;
            line-height: 1.18;
        }

        @include breakpoint-up(xlarge) {
            //font-size: 99px;
            line-height: 1.08;
        }
    }

    .crumbs {
        display: block;
        margin-top: 20px;
        font-size: 15px;

        @include breakpoint-up(medium) {
            display: flex;
            margin-top: 60px;
            font-size: 22px;
        }

        li {
            @include breakpoint-up(medium) {
                padding-right: 35px;
            }

            @include breakpoint-up(large) {
                padding-right: 70px;
            }
        }

        a {
            border-bottom: 1px solid currentColor;
            opacity: 1;
            transition: all 0.15s ease-in-out;

            &:hover {
                border-bottom-width: 0;
                opacity: 0.75;
            }
        }
    }

    .footer {
        box-sizing: border-box;
        color: #696768;
        font-size: 13px;
        line-height: 1.6;
        padding-top: 25px;

        @include breakpoint-up(medium) {
            font-size: 15px;
            padding-top: 100px;
        }
    }
}
