.page-swiper {
    transform: translateZ(0);
    font-size: 1em;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    z-index: 1;
    overflow: hidden;

    @include breakpoint-up(large) {
        height: 100vh;
    }

    .wrapper {
        display: flex;
        height: 100%;
    }

    .slide {
        box-sizing: border-box;
        color: $white;
        background: $black;
        line-height: 1.35;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: flex-end;
        padding: 25px;
        min-height: 280px;
        width: 290px;

        @include breakpoint-up(large) {
            width: 480px;
            padding: 70px 50px;
        }

        * {
            position: relative;
            z-index: 2;
        }

        &.intro {
            background: $white;
            color: $black;
            position: relative;
            min-height: 90vh;
            padding-top: 130px;

            @include breakpoint-up(large) {
                width: 860px;
                padding-left: 100px;
                padding-right: 100px;
            }

            .arrow {
                cursor: pointer;
                position: absolute;
                bottom: 25px;
                right: 25px;
                width: 23px;
                height: 50px;
                content: "";
                opacity: 0.5;
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjNweCIgaGVpZ2h0PSI1MHB4IiB2aWV3Qm94PSIwIDAgMjMgNTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGcgc3Ryb2tlPSIjMjIyMjIyIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgIDxwYXRoIGQ9Ik0xMS41LDQ4IEwxMS41LDAuNjY3OTY4NzUgTDExLjUsNDggWiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9wYXRoPgogICAgPHBvbHlsaW5lIHBvaW50cz0iMjIgMzkgMTEuNSA0OSAxIDM5IDEgMzkiPjwvcG9seWxpbmU+CiAgPC9nPgo8L3N2Zz4K") no-repeat 50%;
                transition: opacity 0.2s ease;

                @include breakpoint-up(large) {
                    transform: rotate(0);
                    bottom: 70px;
                    right: 50px;
                    width: 73px;
                    height: 21px;
                    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzNweCIgaGVpZ2h0PSIyM3B4IiB2aWV3Qm94PSIwIDAgNzMgMjMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgPGcgc3Ryb2tlPSIjMjIyMjIyIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgIDxwYXRoIGQ9Ik03MS41LDExLjUgTDEuMjk2ODc1LDExLjUgTDcxLjUsMTEuNSBaIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+CiAgICA8cG9seWxpbmUgcG9pbnRzPSI2MiAxIDcyIDExLjUgNjIgMjIgNjIgMjIiPjwvcG9seWxpbmU+CiAgPC9nPgo8L3N2Zz4K");
                }
            }
        }

        .content,
        a {
            display: flex;
            flex-direction: column;
        }

        a {
            margin: 0;
            padding: 25px;
            box-sizing: border-box;
            justify-content: flex-end;
            color: inherit;
            text-decoration: none;
            //position: relative;
            //min-height: 280px;

            min-height: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;

            @include breakpoint-up(large) {
                padding: 70px 50px;
            }
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            &::after {
                background-color: rgba(0, 0, 0, 0.3);
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .caption {
            padding-top: 15px;
            order: 1000;

            @include breakpoint-up(medium) {
                padding-top: 30px;
            }
        }

        .title {
            padding-bottom: 15px;
            font-size: 30px;
            font-weight: $font-weight-bold;
            line-height: 1.2;

            @include breakpoint-up(medium) {
                font-size: 38px;
                padding-bottom: 30px;
            }

            @include breakpoint-up(xxlarge) {
                font-size: 45px;
            }
        }

        .text {
            display: none;
        }
    }
}
