input,
select,
textarea {
    border: 0;
    border-bottom: 1px solid rgba($white, 0.2);
    font-size: 22px;
    font-family: $font-family-now;
    color: $white;
    padding: 0 0 9px;
    box-sizing: border-box;
    width: 100%;
    display: block;
    appearance: none;
    border-radius: 0;
    margin: 0;
    line-height: 1;
    font-weight: $font-weight-normal;
    background: transparent;

    @include breakpoint-up(xlarge) {
        padding-bottom: 11px !important;
    }
}

textarea {
    resize: none;
    height: 100px;
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: $white;
    opacity: 0.5;
}

input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
    color: $white;
    opacity: 0.5;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: $white;
    opacity: 0.5;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
    color: $white;
    opacity: 0.5;
}

form {
    .flex {
        display: block;

        @include breakpoint-up(medium) {
            display: flex;
            gap: 20px;
        }

        @include breakpoint-up(large) {
            gap: 40px;
        }
    }

    .field {
        margin-bottom: 12px;
        text-align: left;

        @include breakpoint-up(medium) {
            margin-bottom: 20px;
            flex: 1;
        }
    }

    button[type="submit"] {
        appearance: none;
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzNweCIgaGVpZ2h0PSIyM3B4IiB2aWV3Qm94PSIwIDAgNzMgMjMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzLjIgKDcyNjQzKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8cGF0aCBkPSJNNzEsMTEuNSBMMC43OTY4NzUsMTEuNSBMNzEsMTEuNSBaIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+CiAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoIiBzdHJva2U9IiNGRkZGRkYiIHBvaW50cz0iNjIgMSA3MiAxMS41IDYyIDIyIDYyIDIyIj48L3BvbHlsaW5lPgogICAgPC9nPgo8L3N2Zz4=") no-repeat 100%;
        font-size: 24px;
        padding: 0 100px 0 0;
        border: 0 none;
        color: #fff;
        margin: 25px 0 0;
        cursor: pointer;

        @include breakpoint-up(medium) {
            margin-top: 40px;
        }
    }
}

.form-control {
    position: relative;
    padding-top: 20px;

    @include breakpoint-up(medium) {
        padding-top: 22px;
    }

    @include breakpoint-up(xlarge) {
        padding-top: 25px;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0;
        height: 1px;
        background: $white;
        transition: width 0.3s ease;
        z-index: 2;
    }

    &.focus {
        &::before {
            width: 100%;
        }
    }

    .form-control-label {
        pointer-events: none;
        font-size: 18px;
        position: absolute;
        top: 0;
        padding-top: 20px;
        margin-bottom: 1px;
        display: block;
        line-height: 1;
        opacity: 0.3;
        transition: all 0.2s ease;

        @include breakpoint-up(medium) {
            padding-top: 22px;
        }

        @include breakpoint-up(xlarge) {
            padding-top: 25px;
        }
    }

    &.filled {
        .form-control-label {
            font-size: 15px;
            padding-top: 0;
            opacity: 1;
        }
    }

    br {
        display: none !important;
    }
}

// Contact Form Plugin styles
.wpcf7-not-valid-tip {
    display: none !important;
}

.wpcf7-not-valid {
    border-bottom-color: rgba(220, 50, 50, 0.5);
}
