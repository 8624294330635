// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);

    @if not $n {
        @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
    }

    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $breakpoints) {
    $min: map-get($breakpoints, $name);

    @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $breakpoints) {
    $next: breakpoint-next($name, $breakpoints);

    @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin breakpoint-up($name, $orientation: null, $breakpoints: $breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @if $orientation {
            @media only screen and (min-width: $min) and (orientation: $orientation) {
                @content;
            }
        }

        @else {
            @media (min-width: $min) {
                @content;
            }
        }
    }

    @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin breakpoint-down($name, $orientation: null, $breakpoints: $breakpoints) {
    $max: breakpoint-max($name, $breakpoints);

    @if $max {
        @if $orientation {
            @media only screen and (max-width: $max) and (orientation: $orientation) {
                @content;
            }
        }

        @else {
            @media (max-width: $max) {
                @content;
            }
        }
    }

    @else {
        @content;
    }
}

@mixin unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin safe-area($padding) {
    @supports (padding: calc(max(0))) {
        padding-left: calc(max(#{ $padding }, env(safe-area-inset-left)));
        padding-right: calc(max(#{ $padding }, env(safe-area-inset-right)));
    }
}

@mixin backdrop($blur, $bg, $saturate: false) {
    $backdrop: blur($blur);

    @if $saturate {
        $backdrop: $backdrop saturate($saturate);
    }

    background-color: $bg;

    @supports ((-webkit-backdrop-filter: $backdrop) or(backdrop-filter: $backdrop)) {
        -webkit-backdrop-filter: $backdrop;
        backdrop-filter: $backdrop;
        background-color: $bg;
    }
}

@mixin overlay($offset: 0) {
    bottom: $offset;
    left: $offset;
    position: absolute;
    right: $offset;
    top: $offset;
}
